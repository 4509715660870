const months = [
         "Styczeń",
         "Luty",
         "Marzec",
         "Kwiecień",
         "Maj",
         "Czerwiec",
         "Lipiec",
         "Sierpień",
         "Wrzesień",
         "Październik",
         "Listopad",
         "Grudzień"
]

export default months